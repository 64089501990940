$marginPage: 1rem;
$font-family-default: "Montserrat", "Open Sans", "Helvetica Neue", "Helvetica",
  Helvetica, Arial, sans-serif;
$font-family-accent: "Roboto", "Open Sans", "Helvetica Neue", "Helvetica",
  Helvetica, Arial, sans-serif;
$font-family-mono: "SpaceMono";
$font-size: 16px;
$line-height: 1.5em;

$blue: #0593e7;
$grey: #333;
$grey-dark: darken($grey, 7%);
$grey-light: lighten($grey, 20%);
$padding: $font-size;

/**   colors   **/
$color-orange: #ffa800;
$color-gray: $grey;
$color-dark-gray: $grey-dark;
$color-light-gray: $grey-light;
$color-black: darken($color-dark-gray, 8%);
$color-gray-2: #ddd;
$color-gray-3: #999;

/**   backgrounds   **/
$bg-default: $color-gray;
$bg-accent: $color-orange;
$bg-accent-dark: darken($bg-accent, 20%);
$bg-darken: $color-dark-gray;

/**   borders   **/
$border-accent: $color-orange;
$border-accent-dark: darken($border-accent, 20%);
$border-darken: $color-black;
$border-default: $color-gray;
$border-light: $color-gray-3;

/**   text   **/
$text-default: $color-gray-2;
$text-light: $color-gray-3;
$text-darken: $color-dark-gray;
$text-accent: $color-orange;

/** padding **/
$padding-full: 80px;
$padding-half: 40px;
$padding-small: 20px;
$padding-micro: 10px;

/** grid **/
$grid-gap-micro: $padding-micro;
$grid-gap-small: $padding-small;
$grid-gap-half: $padding-half;
$grid-gap-full: $padding-full;

/** font-size **/
$font-size-default: 16px;
$font-size-huge: 34px;
$font-size-large: 18px;
$font-size-small: 12px;
$font-size-micro: 10px;

$max-width: 800px;
$logo-size: 100px;
