$font-base-path: "../fonts/";
@import "settings";
@import "fonts/SpaceMono/main";
@import "fonts/Montserat/main";
@import "fonts/RobotoMono/main";

@import "elements/header";

@import "sections/block";

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $bg-default;
  font-size: $font-size;
  color: $text-default;
  line-height: $line-height;
  font-family: $font-family-default;
}

.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family-default;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
  overflow: hidden;
  max-width: 100%;

  > * {
    width: 100%;
  }

  > article {
    max-width: $max-width;
    flex-grow: 1;
    margin-bottom: $padding-half;
  }
}

a {
  color: $text-accent;
}

.icon {
  margin-right: 0.5rem;
}

.nav {
  position: relative;
  max-width: $max-width;
  margin: 0 auto;

  @include vertical($gap: 5px, $selector: "a");

  @include tablet() {
    @include horizontal(
      $overflow: visible,
      $selector: "a",
      $justify-content: flex-start
    ) {
    }
  }

  a {
    color: $text-light;
    padding: $padding-micro $padding-small;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    text-align: center;

    @include col($width: 2, $grow: 0);

    &:hover {
      color: $text-default;
      border-bottom: 3px solid $border-accent;
      margin-bottom: -3px;
    }
  }
}


.banner {
  @include tablet() {
    @include horizontal();

    > div {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}

.company {
  text-align: center;

  h1 {
    color: $text-accent;
    display: inline;
    font-family: $font-family-accent;
    font-size: $logo-size * 0.8;
    font-weight: bold;
    height: 100%;
    left: 0;
    line-height: $logo-size;
    margin: 0;
    position: relative;
    text-decoration: none;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
    top: 0;
    width: 100%;

    @include tablet() {
      font-size: $logo-size;
      line-height: $logo-size * 0.8;
      text-align: left;
    }
  }

  span {
    position: absolute;
    display: block;
    right: 0;
    bottom: 0;
    font-size: $font-size-large;
    line-height: $font-size-large;
    color: $text-light;
  }
}

