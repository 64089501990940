@import "../settings";
@import "../mixin/flex_box";
@import "../mixin/breakpoints";

.type_block {
  color: $text-default;
  margin-bottom: $padding-full;
  position: relative;

  div {
    padding: $padding-micro;

    @include tablet() {
      padding: $padding-half;
    }

    &:first-child {
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0%, -50%);

      h3 {
        font-size: 2rem;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        text-decoration: underline;
        text-decoration-color: $bg-accent-dark;
        border-bottom: 5px solid rgba(193, 200, 209, 0.2);
        line-height: 2.1rem;
      }
    }

    &:last-child {
      padding-top: $padding-half;
      background-color: rgba(255, 255, 255, 0.05);
    }

    *:first-of-type {
      margin-top: 0;
    }

    *:last-of-type {
      margin-bottom: 0;
    }
  }
}
