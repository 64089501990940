@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./montserrat-v29-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-italic - latin */
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("./montserrat-v29-latin-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-900 - latin */
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: url("./montserrat-v29-latin-900.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
