@import "settings";
@import 'mixin/breakpoints';


.logo {
  position: relative;
  width: $logo-size;
  height: $logo-size;
  margin: auto;

  @include tablet() {
    /** dont merge both margin rules, separation is required **/
    margin: unset;
    margin-right: calc($logo-size * 0.15);
  }

  /** the X in the circle **/
  > span {
    position: absolute;
    width: $logo-size;
    height: $logo-size;
    border: calc($logo-size * 0.1) solid rgba(193, 200, 209, 0.2);
    border-radius: 50%;
    animation: spin 30s infinite linear;
    animation-timing-function: ease-in-out;
    border-top-color: $color-orange;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }

  > div {
    animation: grow 30s infinite linear;
    animation-timing-function: ease-in-out;
    padding-left: 3px;
    width: $logo-size;
    height: $logo-size;
    line-height: $logo-size;
    display: inline-block;
    clip-path: circle($logo-size / 2 - ($logo-size * 0.13));
    color: white;
    font-size: calc($logo-size * 1.5);
    font-weight: 900;
    font-family: "Space Mono", serif;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  }
}

@keyframes grow {
  20% {
    clip-path: circle($logo-size / 2 - ($logo-size * 0.13));
  }
  50% {
    clip-path: circle($logo-size / 2 - ($logo-size * 0.08));
  }
  65% {
    clip-path: circle($logo-size / 2 - ($logo-size * 0.08));
  }

  85% {
    clip-path: circle($logo-size / 2 - ($logo-size * 0.13));
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  20% {
    border-top-color: $color-orange;
    border-width: calc($logo-size * 0.1);
  }
  50% {
    border-width: 3px;
  }
  90% {
    border-top-color: $color-orange;
    border-width: calc($logo-size * 0.1);
  }
  to {
    transform: rotate(360deg);
  }
}
