@import "../Layout/settings";
@import "../Layout/mixin/flex_box";
@import "../Layout/mixin/breakpoints";

.stage {
  padding: $padding-full 0;
  @include vertical(1);

  @include laptop() {
    padding: $padding-full;
  }

  > div {
    padding: $padding-half $padding-micro $padding-micro $padding-micro;

    @include tablet() {
      padding: $padding-half;
    }

    @include laptop() {
      padding: $padding-full;
    }
  }

  > div:first-of-type {
    z-index: 2;
    align-items: center;
    position: relative;
    padding: 0;

    h2,
    h1 {
      width: 100%;
      margin: 0;
      font-size: calc($font-size * 1.3);
      text-shadow: 0 0 8px #000;
      text-align: center;
      line-height: 1.5rem;

      @include mobile-l() {
        font-size: calc($font-size * 2);
        line-height: 3.5rem;
      }

      @include laptop() {
        font-size: calc($font-size * 3);
      }
    }
  }

  > div:last-of-type {
    background-color: rgba(255, 255, 255, 0.05);
    border-top: 2px solid $bg-accent-dark;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: calc($font-size * 1.1);
    justify-content: center;
    line-height: calc($font-size * 1.6);
    transform: translate(0, -25px);
    z-index: 1;

    @include col(5);
    @include vertical($selector: "p");

    @include tablet() {
      padding: $padding-full;
    }

    p {
      margin: 0 0 $padding-micro 0;

      &:last-of-type {
        margin: 0;
      }
    }
  }
}
