@import "../Layout/settings";
@import "../Layout/mixin/flex_box";
@import "../Layout/mixin/breakpoints";

.btn {
  background-color: $bg-default;
  border-radius: 5px;
  border: 1px solid $border-light;
  color: white;
  font-weight: bold;
  margin: 0;
  padding: $padding-small $padding-half;
  text-align: center;
  text-decoration: none;
  width: 100%;

  @include tablet() {
    width: auto;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.accent {
  background-color: $bg-accent;
  border-color: $border-default;
  color: $text-darken;
}

.btn_icon {
  padding-right: 0.5rem;
}

.type_contact {
  align-items: center;
  background-color: $bg-darken;
  padding: $padding-full;
  text-align: center;
  margin: $padding-full 0;

  @include vertical($gap: $grid-gap-half);

  .text {
  }
}

.headline {
  color: $text-accent;
  font-size: $font-size-large * 2;
  margin: 0 0 $padding-small;
}

.small {
  color: $text-light;
}

.buttons {
  max-width: 100%;
  min-width: 300px;
  width: 100%;

  @include vertical($gap: $grid-gap-small);

  @include mobile-l() {
    @include horizontal($gap: $grid-gap-small, $justify-content: space-evenly);
  }
}
