@import "../Layout/settings";
@import "../Layout/mixin/breakpoints";

.footer {
  border-top: 3px solid $border-accent-dark;
  background: rgb(75, 75, 75);
  background: linear-gradient(
    0deg,
    rgba(40, 40, 40, 1) 0%,
    rgba(51, 51, 51, 1) 100%
  );
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.2);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: $padding-micro;
  color: $text-light;
  margin-top: $padding-full;
  padding: $padding-half 0;

  > div {
    flex-grow: 1;
    text-align: center;

    display: flex;

    /** company copy right **/
    &:first-child {
      font-size: $font-size-small;
      align-self: stretch;
      justify-content: center;
      align-items: center;

      @include tablet() {
        font-size: $font-size-default;
      }
    }

    &:nth-child(2) {
      align-self: stretch;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: $padding-micro;

      > div {
        display: flex;
        justify-content: flex-end;
        padding: $padding-micro;
        align-items: center;
        gap: $padding-small;
        align-self: stretch;
      }
    }
  }
}

.link {
  text-decoration: none;
  color: $text-light;
}

.icon {
  font-size: $font-size-large * 1.5;
}
