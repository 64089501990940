@font-face {
  font-display: swap;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: url("./roboto-mono-v23-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-mono-italic - latin */
@font-face {
  font-display: swap;
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 400;
  src: url("./roboto-mono-v23-latin-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-mono-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  src: url("./roboto-mono-v23-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
